// React requires
import React from "react";
import styled from "styled-components";
import {device} from "../../assets/styles/variables/breakpoints";

// React assets
import logo from "../../assets/images/logo.svg";

type Variants = "dark" | "light";

// Prop types
type propVariant = {
    variant?: string | Variants;
    width?: number;
    marginTop?: number;
};

const Logo = (props: propVariant) => {
    const style = {
        variant: props['variant'],
        width: props['width'],
        marginTop: props['marginTop']
    }

    return (
        <SCLogo {...style}>
            <img src={logo} alt="NBM Engineering Logo"/>
        </SCLogo>
    );
};

const themes: any = {
    dark: "none",
    light:
        "invert(100%) sepia(95%) saturate(0%) hue-rotate(131deg) brightness(106%) contrast(104%)",
};

const SCLogo = styled.div`
  width: ${(prop: propVariant) => (prop.width ? `${prop.width}px` : "100%")};
  display: flex;
  margin-top: ${(prop: propVariant) =>
    prop.marginTop ? `${prop.marginTop}px` : 0};
  object-fit: cover;
      height: 100%;
      display: block;
      padding: 0;


  img {
    filter: ${(prop: propVariant) => prop.variant && themes[prop.variant]};
    max-width: 100%;
  }

  @media ${device.sm}  {
    width: 120px;
  }
`;

export default Logo;
