const typography = {
    //Headings
    heading: {
        "font-size": "3.2rem",
        "font-weight": "bold",
        "text-transform": "uppercase",
        "line-height": "1.36",

        tablet: {
            "font-size": "2em",
        },
        mobile: {
            "font-size": "1.875em",
        },
    },
    subheading: {
        "font-size": "1.6em",
        "font-weight": "500",
        desktop: {
            "font-size": "2.24em",
        },
        tablet: {
            "font-size": "1.5em",
        },
        mobile: {
            "font-size": "1.2em",
        },
    },
    //Titles
    title: {
        "font-size": "1.2em",
        "font-weight": "bold",
        "text-transform": "uppercase",
        tablet: {
            "font-size": "1.2em",
            "font-weight": "600",
            "text-transform": "uppercase",
        },
        mobile: {
            "font-size": "1.2em",
            "text-transform": "uppercase",
        },
    },
    subtitle: {
        "font-size": "1.6rem",
        "font-weight": "500",
        desktop: {
            "font-size": "1.8em",
        },
        tablet: {
            "font-size": "1.2em",
        },
        mobile: {
            "font-size": "1.2em",
        },
    },
    small_subtitle: {
        "font-size": "1em",
        "font-weight": "600",
        tablet: {
            "font-size": "1em",
        },
        mobile: {
            "font-size": "1em",
        },
    },
    //Paragraphs
    body_large: {
        "font-size": "1.4rem",
        "font-weight": "400",
        desktop: {
            "font-size": "1.4rem",
        },
        tablet: {
            "font-size": "1.2 reem",
        },
        mobile: {
            "font-size": "1rem",
        },
    },
    body_medium: {
        "font-size": "1.2rem",
        "font-weight": "500",
        "line-height": "1.6",
        desktop: {
            "font-size": "1.2em",
        },
        tablet: {
            "font-size": "1em",
        },
        mobile: {
            "font-size": ".7rem",
        },
    },
    body_small: {
        "font-size": "1 rem",
        "font-weight": "500",
        "line-height": "1.8",
        desktop: {
            "font-size": "1em",
        },
        tablet: {
            "font-size": "1em",
        },
        mobile: {
            "font-size": "0.8rem",
        },
    },
    small: {
        "font-size": "0.87em",
        "font-weight": "400",
        "line-height": "1.6",
        tablet: {
            "font-size": "0.87em",
        },
        mobile: {
            "font-size": "0.87em",
        },
    },
    //Navigation
    navigation: {
        header: {
            "font-size": "1.4em",
            "font-weight": "bold",
            "text-transform": "uppercase",
            "text-shadow": "0px 3px 3px rgba(0,0,0,0.1)",
            tablet: {
                "font-size": "1.1em",
            },
            mobile: {
                "font-size": "1.25em",
            },
        },
        footer: {
            "font-size": "1.37em",
            tablet: {
                "font-size": "1.37em",
            },
            mobile: {
                "font-size": "0.75em",
                "font-weight": "600",
            },
        },
        footer_text: {
            "font-size": "1.37em",
            "font-weight": "100",
            mobile: {
                "font-size": "0.75em",
            },
        },
        subnavigation: {
            "font-size": "1.37em",
            "font-weight": "bold",
            tablet: {
                "font-size": "1.37em",
            },
            mobile: {
                "font-size": "1em",
                "font-weight": "500",
            },
        },

        tabs: {
            "font-size": "1em",
            "font-weight": "bold",
            tablet: {
                "font-size": "1em",
            },
            mobile: {
                "font-size": "1em",
                "font-weight": "600",
            },
        },
    },
    //Links
    link_primary: {
        "font-size": "1.2rem",
        "font-weight": "bold",
        "text-transform": "uppercase",
        tablet: {
            "font-size": "1.6em",
            "font-weight": "600",
        },
        mobile: {
            "font-size": ".8rem",
        },
    },
    //Buttons
    button_primary: {
        "font-size": "1em",
        "font-weight": "600",
        tablet: {
            "font-size": "1em",
        },
        mobile: {
            "font-size": "1em",
        },
    },
    button_secondary: {
        "font-size": "1em",
        "font-weight": "400",
        tablet: {
            "font-size": "1em",
        },
        mobile: {
            "font-size": "1em",
        },
    },
};

export default typography;