import styled from "styled-components";
import colors from "../../../assets/styles/variables/colors";
import {space} from "../../../assets/styles/variables/spaces";
import {device} from "../../../assets/styles/variables/breakpoints";

type propType = {
  underline?: boolean;
};

export default styled.div`
display: contents;

&:after {
    content: "";
    background-color: ${colors.secondary};
    width: calc( 100% - 30px);
    height: 3px;
    position: relative;
    top: ${space(5)};
    display: block;
    margin: 0 auto;

    @media ${device.md} {
      ${(props: propType) =>
      props.underline ?
      `
      background-color: rgba(255, 255, 255, 1);
      height: 1px` : `content: none`
      
    }
  }
}
`