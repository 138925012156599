// React requires
import React from "react";

// Prop types
type Props = {
  image: string;
  alt?: string;
  size?: number;
};

const Icon = (props: Props) => {
  return <img src={props.image} alt={props.alt} width={props.size || "45px"} />;
};

export default Icon;
