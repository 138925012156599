import {
    ABOUT_PAGE,
    CAREER_PAGE,
    CONTACT_PAGE,
    HOME_PAGE,
    ACCESSIBILITY_PAGE,
    NOT_FOUND_PAGE,
    INDUSTRIES_PAGE
} from "../app/consts/pages";
import {MetaRoutes, MetaRoute} from "../app/types/route";

const list: MetaRoutes<MetaRoute> = {
    [HOME_PAGE]: {
        name: 'meta.home.name',
        title: 'meta.home.title',
        description: 'meta.home.description',
        keywords: 'meta.home.keywords',
        image: '',
    },
    [ABOUT_PAGE]: {
        name: 'meta.about.name',
        title: 'meta.about.title',
        description: 'meta.about.description',
        keywords: 'meta.about.keywords',
        image: '',
    },
    [CAREER_PAGE]: {
        name: 'meta.career.name',
        title: 'meta.career.title',
        description: 'meta.career.description',
        keywords: 'meta.career.keywords',
        image: '',
    },
    [INDUSTRIES_PAGE]: {
        name: 'meta.industries.name',
        title: 'meta.industries.title',
        description: 'meta.industries.description',
        keywords: 'meta.industries.keywords',
        image: '',
    },
    [CONTACT_PAGE]: {
        name: 'meta.contact.name',
        title: 'meta.contact.title',
        description: 'meta.contact.description',
        keywords: 'meta.contact.keywords',
        image: '',
    },
    [ACCESSIBILITY_PAGE]: {
        name: 'meta.accessibility.name',
        title: 'meta.accessibility.title',
        description: 'meta.accessibility.description',
        keywords: 'meta.accessibility.keywords',
        image: '',
    },
    [NOT_FOUND_PAGE]: {
        name: 'meta.not_found.name',
        title: 'meta.not_found.title',
        description: 'meta.not_found.description',
        keywords: 'meta.not_found.keywords',
        image: '',
    }
};

export default list;