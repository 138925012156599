// React libraries
import i18n from 'i18next';

// React translations
import resources from '../lang';

i18n.init({
    resources,
    lng: process.env.REACT_APP_LANG,
    fallbackLng: process.env.REACT_APP_FALLBACK_LANG,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
        formatSeparator: ','
    }
});

export default i18n;
