const size = {
    xs: 376,
    sm: 577,
    md: 767.5,
    lg: 1160,
    xl: 1401
}

export const device = {
    xs: `screen and (max-width: ${size.xs}px)`,
    sm: `screen and (max-width: ${size.sm}px)`,
    md: `screen and (max-width: ${size.md}px)`,
    lg: `screen and (max-width: ${size.lg}px)`,
    xl: `screen and (max-width: ${size.xl}px)`
};