import styled from "styled-components";
import typography from "../../../assets/styles/variables/typography";
import { device } from "../../../assets/styles/variables/breakpoints";
import colors from "../../../assets/styles/variables/colors";

type propType = {
  color?: any;
};

export default styled.div`
  ${typography.small};
  color: ${(props: propType) => props.color || colors.primary};

  @media ${device.md} {
    ${typography.small.tablet};
  }

  @media ${device.sm} {
    ${typography.small.mobile};
  }
`;
