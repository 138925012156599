// React requires
import React from "react";
import {Link} from "react-router-dom";

//React assets
import styled from "styled-components";
import colors from "../../../assets/styles/variables/colors";

type propType = {
    icon?: string;
    to: any;
    variant?: string;
    children: any;
};

const Button = (props: propType) => {
    return (
        <SCButton to={props.to}>
            {props.children}
            <img src={props.icon} alt="icon"/>
        </SCButton>
    );
};

const SCButton = styled(Link)`
  background-color: ${colors.white};
  color: ${colors.primary};
  text-align: center;
  padding: 10px 25px;
  text-decoration: none;
  font-weight: normal;
  &:hover{
    opacity: 0.85;
  }
  img {
    position: relative;
    top: -1px;
    left: 3px;
  }
`;

export default Button;