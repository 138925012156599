const spaces: any = [
    "0", // 0
    "4", // 1
    "8", // 2
    "12", // 3
    "16", // 4
    "20", // 5
    "24", // 6
    "32", // 7
    "40", // 8
    "48", // 9
    "56", // 10
    "64", // 11
    "80", // 12
    "96", // 13
    "104", // 14
    "128", // 15
    "148", // 16
    "168", // 17
    "240", // 18
    "260", // 19
    "424", //20
    "320", //21
];

const context = 16;

export const space = (n: any) => {
    if (spaces[n]) {
        return spaces[n] / context + "rem";
    }

    throw new Error('Unknow space, param: ' + n);
};
