// React requires
import React, {useState, useEffect} from "react";

// React libraries
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Container, Row} from "react-grid-system";

// React components
import Navigation from "../Navigation";

// React assets
import SCHeader from "./SC-Header";
import SCNavigation from "./SC-Navigation";

// React custom configs
import routes from "../../../routes";

type Props = RouteComponentProps<any> & {
    location: string;
};

const Header = (props: Props) => {
    const [isWhite, setIsWhite] = useState(true);
    const [isWhiteBg, setIsWhiteBg] = useState(false);
    const landingPage = "home";
    const contactPage = "contact";
    const careersPage = "careers";
    const industriesPage = "industries";
    const accessibilityPage = "accessibility";

    const getRouteName = () => {
        const list = routes.find((item) => item.path === props.location.pathname);
        return list ? list.name : {name: '',};
    };

    useEffect(() => {
        setIsWhite(getRouteName() === landingPage);
        setIsWhiteBg(getRouteName() === contactPage || getRouteName() === careersPage || getRouteName() === industriesPage || getRouteName() === accessibilityPage);
    }, [props]);

    const variant = {variant: {theme: (isWhite ? "light" : ''), background: isWhiteBg}};
    const underline = {underline: !isWhiteBg}

    return (
        <SCHeader {...variant}>
            <Container>
                <Row align="center">
                    <SCNavigation {...underline} >
                        <Navigation isWhiteBg={isWhite}/>
                    </SCNavigation>
                </Row>
            </Container>
        </SCHeader>
    );
};

export default withRouter(Header);
