import styled from "styled-components";
import colors from "../../../assets/styles/variables/colors";
import {device} from "../../../assets/styles/variables/breakpoints";
import footerLogo from "../../../assets/images/footer-logo.png";
import {space} from "../../../assets/styles/variables/spaces";

export const SCFooter = styled.footer`
  padding: 124px 0 8px;
  background: ${colors.primary};
  color: ${colors.footer.text};
  font-weight: lighter;
  font-size: 0.9rem;
  position: relative;
  margin-top: ${space(17)};
  @media ${device.sm} {
    padding: 8px 0;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -106px;
    right: 0;
    margin: 0 auto;
    background-image: url(${footerLogo});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: block;
    width: 300px;
    height: 156px;

    @media ${device.xl} {
      top: -100px;
      width: 228px;
      height: 148px;
    }

    @media ${device.md} {
      top: -56px;
      width: 126px;
      height: 82px;
    }
  }

  h3 {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 2.5em;

    @media ${device.md} {
      margin-bottom: 0;
    }

    @media ${device.sm} {
      margin-top: 50px;
    }
  }

  h4 {
    color: ${colors.tetriary};
    margin-bottom: 0;
  }
  h4 + p {
    margin-top: 0.4em;
  }

  .accessibility-link {
    color: ${colors.footer.text};
  }

  @media ${device.xl} {
    margin-top: ${space(16)};
  }
`;

export const CopyRight = styled.div`
  padding: 6rem 0 0;

  div {
    text-align: center;
    padding: 1.3rem 0 1rem 0;
    margin: 0;
  }
`;

export const Line = styled.span`
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(255,255,255,0.2);
  margin: 0 auto;
  left: 0;
  right: 0;
`;

export const PhoneNumber = styled.a`
align-items: center;
display: inline-block;

img {
  margin-right: 16px;
}

`;